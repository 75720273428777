import React from 'react'
import { Outlet, NavLink } from "react-router-dom";
import './Layout.css'
import pdf from '../Resume/Resume.pdf'


const Layout = () => {


    return (
        <div className="layot">
            <nav className="nav">
                <ul className="header">
                    <li className="initial">
                        G
                    </li>
                    <li>
                        <NavLink className='link' to="/">Home</NavLink>
                    </li>
                    <li>
                        <NavLink className='link' to="/projects">Projects</NavLink>
                    </li>
                    <li>
                        <NavLink className='link' to="/contact">Contact</NavLink>
                    </li>
                    <li>
                        <a className='link' href={pdf} download>Resume</a>
                    </li>
                </ul>
            </nav>

            <Outlet className='page' />

        </div>
    )
};

export default Layout;