import './Home.css'
import './page.css'
import Img from '../images/1310618_e8608.gif'
import profileImg from '../images/gurleen1.png'
import { BsGithub, BsGraphUpArrow } from 'react-icons/bs'
import { AiFillHeart } from 'react-icons/ai'



const Home = () => {


    return (
        <div className="page">
            <h1>Gurleen</h1>
            <div className='block'>
                <h1>Hi, I am a Web Developer  </h1>
                <img src={Img} alt="Loding" className='logo' />
            </div>
            <div className='profileContainer'>
                <img src={profileImg} className='profile' alt='profile' />
                <aside>
                    <a href='https://github.com/GurleenGurleen' target='_blank' rel="noreferrer">
                        <BsGithub /></a>
                    <span > <AiFillHeart /></span>
                    <span ><BsGraphUpArrow /></span>

                </aside>

            </div>

            <p>As a passionate and driven individual, I am constantly seeking new opportunities
                to learn and grow. With a deep-seated love for coding, I thrive in environments
                that challenge me to solve complex problems and push my limits. From developing
                innovative solutions to collaborating with diverse teams, I am always eager to
                apply my problem-solving skills and make meaningful contributions. With a strong
                foundation in programming languages and a natural curiosity for exploring emerging technologies,
                I am excited to continue expanding my knowledge and skillset in the ever-evolving world of web
                development.</p>


        </div>
    );
};

export default Home;
