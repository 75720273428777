import './page.css'
import './Project.css'
import Img1 from '../images/img1.jpeg'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'
import "swiper/css/navigation";
import { Navigation } from "swiper";




const Projects = () => {

    return (
        <div className="swiperslide">
            <Swiper
                className='swiperContainer'
                navigation={true} modules={[Navigation]}

                spaceBetween={50}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
            >
                <SwiperSlide className='swiper'>

                    <div className='container'>
                        <a href='https://plain-red-donkey.cyclic.app/' rel="noreferrer" target='_blank'>Demo</a>
                        <img src={Img1} alt='img' />
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua.
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                            ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                            sunt in culpa qui officia deserunt mollit anim id est laborum.

                        </p>

                    </div>

                </SwiperSlide>
                <SwiperSlide>

                    <div className='container'>

                        <a href='https://plain-red-donkey.cyclic.app/' rel="noreferrer" target='_blank'>Demo</a>

                        <img src={Img1} alt='img' />
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua.
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                            ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                            sunt in culpa qui officia deserunt mollit anim id est laborum.

                        </p>

                    </div>

                </SwiperSlide>
                <SwiperSlide>

                    <div className='container'>

                        <a href='https://plain-red-donkey.cyclic.app/' rel="noreferrer" target='_blank'>Demo</a>
                        <img src={Img1} alt='img' />
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua.
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                            ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                            sunt in culpa qui officia deserunt mollit anim id est laborum.

                        </p>


                    </div>

                </SwiperSlide>
                <SwiperSlide>

                    <div className='container'>

                        <a href='https://plain-red-donkey.cyclic.app/' rel="noreferrer" target='_blank'>Demo</a>

                        <img src={Img1} alt='img' />
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua.
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                            ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                            sunt in culpa qui officia deserunt mollit anim id est laborum.

                        </p>

                    </div>

                </SwiperSlide>

            </Swiper>
        </div>
    );
};

export default Projects;