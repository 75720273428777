
import './App.css';
import Layout from "./layout/Layout";
import Home from "./pages/Home";
import Projects from "./pages/Project";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Resume from './pages/Resume';
function App() {
  return (
    <div className='app'>
      <BrowserRouter >
        <Routes >
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="projects" element={<Projects />} />
            <Route path="contact" element={<Contact />} />
            <Route path="resume" element={<Resume />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
