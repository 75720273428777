
import './page.css'
import './Contact.css'


const Contact = () => {
    return (
        <div className="page">

            <h1>Contact Info</h1>
            <br></br>
            <form className='contactContainer' action='https://formsubmit.co/kgurleen276@gmail.com' method='POST'>

                <label>Name</label>
                <input
                    type='text'
                    name='userName'
                />
                <label>Email</label>
                <input
                    type='text'
                    name='email'
                />
                <label>Phone Number</label>
                <input
                    type='text'
                    name='phoneNumber'
                />
                <label>Message</label>
                <textarea
                    name="message">

                </textarea>
                <input type="submit" value="Send"></input>


            </form>

        </div>
    )
};

export default Contact;