import './page.css'
import './Resume.css'

const Resume = () => {
    return (
        <div className="page">

            <div className='resume'>

                <span>Resume </span>
                <h1>Web Designer & Web Developer:</h1>
                <ul>
                    <li>Full Name: Gurleen</li>
                    <li>Phone: +1(647)-613-7042 </li>
                    <li>City: Toronto, Canada</li>
                    <li>Age: 20</li>
                    <li>Degree: Computer Programming Diploma</li>
                    <li>Email: kgurleen276@gmail.com</li>
                </ul>

                <p> Recent college graduate with a bachelor's Diploma in Computer Programming and strong
                    knowledge of both logistics and customer service. experienced in managing multiple tasks and
                    working with a diverse group of people. Possess excellent communication and problem-solving
                    skills with the ability to prioritize tasks and handle time-sensitive situations. highly organized and
                    detail-oriented with a passionate. Seeking to use
                    my skills as a Frontent or Backend or fullstack Developer.
                </p>

                <h2>Eductaion</h2>

                <div className="resumeInfo">
                    <div className="Eductaion">
                        <h3>Computer Programming</h3>
                        <ul>

                            <li>2020 - 2023</li>
                            <li>Seneca College, Markham Campus</li>
                            <li>Learnings: C, C++, java, object oriented Programming, HTML, CSS, javaScript,
                                nodejs, SQL
                            </li>
                        </ul>
                        <h3>React</h3>
                        <ul>
                            <li>Udamey</li>
                            <li>2023</li>

                        </ul>
                    </div>

                    <div className="skills">
                        <h2>Skills</h2>
                        <ul>
                            <li>JavaScript</li>
                            <li>HTML</li>
                            <li>CSS</li>
                            <li>React</li>
                            <li>Nodejs</li>
                        </ul>
                        <h2>Experience</h2>
                        <ul>
                            <li>
                                <p>Worked on number of projects to improve skills.</p>
                            </li>
                        </ul>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Resume;